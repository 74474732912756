import { useState, useEffect } from 'react'
// material
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Container, Grid, Button, useMediaQuery, Typography } from '@mui/material';
import { ethers } from "ethers";
import { useMetaMask } from 'metamask-react';

//
import { varFadeInUp, MotionInView } from '../../animate';
import useAlertMessage from '../../../hooks/useAlertMessage';
import useWallets from '../../../hooks/useWallet';
import { useWallet } from '../../../hooks/useWallets';
import { CHAIN_ID, CONTRACT_ABI, CONTRACT_ADDRESS, NFT_PUBLIC_PRICE,NFT_WHITELIST_PRICE, MINT_STATUS } from '../../../utils/constants';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  margin: theme.spacing(14, 0, 0, 0),
  paddingTop:'10%', 
  paddingBottom:'10%', 
  background: 'url(/assets/para/header_bg2.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

const StyledButton = styled('button')(({ theme }) => ({
  color:"#fff",
  
}));
// ----------------------------------------------------------------------

export default function LandingPreppers() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { chainId, ethereum } = useMetaMask();
  const { openAlert } = useAlertMessage();
  const { currentAccount, connectWallet, walletConnected, registerAvailableWhitelist } = useWallets();
  const [totalSupply, setTotalSupply] = useState(0)
  const { active, account, library } = useWallet();
  const init = async () => {
      
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
    const {_hex} = await contract.totalSupply();
    setTotalSupply(Number(_hex))
  
}
  useEffect(() => { 
    
    
    init()
  }); 

  const handleMint = async () => {
    try{
      if (library) {
        var transaction = '';
        const provider = new ethers.providers.Web3Provider(library.provider);
        const signer = provider.getSigner();
        console.log(signer)
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        console.log(contract)

        if (MINT_STATUS == 'private') {
          transaction = await contract.privateMint({ value: ethers.utils.parseEther(String(NFT_WHITELIST_PRICE)) });
        } else {
          transaction = await contract.publicMint({ value: ethers.utils.parseEther(String(NFT_PUBLIC_PRICE)) });
        }
        await transaction.wait();

        openAlert({ severity: 'success', message: 'Minted!' });

      } else {
        openAlert({ severity: 'error', message: 'Ethereum object doesn\'t exist' });
      }

    } catch (error) {
        openAlert({ severity: 'error', message: error.error.message });
    }

  };

  const handlePrivateMint = async () => {
    try{
      if (library) {
        var transaction = '';
        const provider = new ethers.providers.Web3Provider(library.provider);
        const signer = provider.getSigner();
        console.log(signer)
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        console.log(contract)

        
        transaction = await contract.privateMint({ value: ethers.utils.parseEther(String(NFT_WHITELIST_PRICE)) });
        
        
        
        await transaction.wait();

        openAlert({ severity: 'success', message: 'Minted!' });

      } else {
        openAlert({ severity: 'error', message: 'Ethereum object doesn\'t exist' });
      }

    } catch (error) {
        openAlert({ severity: 'error', message: error.error.message });
    }

  };
  


  return (
    <RootStyle
      id="preppers">
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'center' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={6} md={6} >
 	
              
            <MotionInView variants={varFadeInUp}>
              <Stack spacing={{ xs: 2, md: 9 }}  alignItems="center" justifyContent={'center'} sx={{ marginTop: '10%', marginBottom: '10%' }}>
               
              <img src="/assets/para/alphalogo.png" alt="prepper_one" style={{ width: '100%', marginTop: '0%' }} />
  		          
              <Stack style={{
                backgroundColor:"#D1AF76",
                justifyContent:'center',
                alignItems:'center',
                borderRadius:'10px',
                paddingRight:"30px",
                paddingLeft:"30px"
                
              }}>
             
                <Typography sx={{
                    color: 'black',
                    fontWeight: 'normal',
                    letterSpacing:'3px',
                    fontFamily: "'Russo One', sans-serif",
                    fontSize: { xs: '20px', md: '40px' },
                  }}>
                    {100 - totalSupply } REMAINING
                  </Typography>
              </Stack>
                
                {/* <input type="number" className="form-control" placeholder="please input mint price" onChange={(e)=>setPrice(e.target.value)} /> */}
                <Stack flexDirection={'row'}>
                <StyledButton
                  sx={walletConnected ? {
                    color:"#fff",
                    width: { xs: '120px', md: '303px' },
                    height: { xs: '30px', md: '78px' },
                    fontSize: { xs: '17px', md: '30px' },
                    borderRadius: '20px',
                    backgroundColor: '#9A1E16',
                    borderBottom: `2px solid #D5AE6C`,
                    letterSpacing: '4px',
                    fontWeight: 'normal',
                    fontFamily: "'Russo One', sans-serif",
                    marginRight:'15px'
                    
                  } : {
                    color:"rgba(112, 111, 111, 0.65)",
                    width: { xs: '120px', md: '303px' },
                    height: { xs: '30px', md: '78px' },
                    fontSize: { xs: '17px', md: '30px' },
                    borderRadius: '20px',
                    backgroundColor: 'rgba(78, 78, 78, 0.75)',
                    borderBottom: `2px solid #D5AE6C`,
                    letterSpacing: '4px',
                    fontWeight: 'normal',
                    fontFamily: "'Russo One', sans-serif",
                    marginRight:'15px'
                  }
                }
                  onClick={()=> handleMint()}
                  disabled={walletConnected ? false : true}
                >
                  PUBLIC MINT
                </StyledButton>
                <StyledButton
                  sx={walletConnected ? {
                    color:"#fff",
                    width: { xs: '120px', md: '303px' },
                    height: { xs: '30px', md: '78px' },
                    fontSize: { xs: '17px', md: '30px' },
                    borderRadius: '20px',
                    backgroundColor: '#9A1E16',
                    borderBottom: `2px solid #D5AE6C`,
                    letterSpacing: '4px',
                    fontWeight: 'normal',
                    fontFamily: "'Russo One', sans-serif",
                    
                  } : {
                    color:"rgba(112, 111, 111, 0.65)",
                    width: { xs: '120px', md: '303px' },
                    height: { xs: '30px', md: '78px' },
                    fontSize: { xs: '17px', md: '30px' },
                    borderRadius: '20px',
                    backgroundColor: 'rgba(78, 78, 78, 0.75)',
                    borderBottom: `2px solid #D5AE6C`,
                    letterSpacing: '4px',
                    fontWeight: 'normal',
                    fontFamily: "'Russo One', sans-serif",
                    
                  }
                }
                  onClick={()=> handlePrivateMint()}
                  disabled={walletConnected ? false : true}
                >
                  PRIVATE MINT
                </StyledButton>

                </Stack>

                
                
              </Stack>
             
            </MotionInView>
          </Grid>
          
        </Grid>
      </Container>
    </RootStyle >
  );
}
