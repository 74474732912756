import { useState, useEffect } from 'react'
// material
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Container, Typography, Button, useMediaQuery } from '@mui/material';
import { ethers } from "ethers";
//
import { varFadeInUp, MotionInView } from '../../animate';
import useWallets from '../../../hooks/useWallet';
import { useWallet } from '../../../hooks/useWallets';

import useAlertMessage from '../../../hooks/useAlertMessage';
import { useMetaMask } from 'metamask-react';
import { CHAIN_ID, CONTRACT_ABI, CONTRACT_ADDRESS, NFT_PUBLIC_PRICE,NFT_WHITELIST_PRICE, MINT_STATUS } from '../../../utils/constants';
import api from '../../../utils/api';
import { MetamaskErrorMessage } from "../../../utils/MetamaskErrorMessage";
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  background: 'url(/assets/para/camp.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
}));
// ----------------------------------------------------------------------

export default function LandingMint() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { currentAccount, connectWallet, walletConnected, registerAvailableWhitelist } = useWallets();
  //const { mintAvailableWhitelist } = useWhitelist();
  const { chainId, ethereum } = useMetaMask();
  const { openAlert } = useAlertMessage();
  const [totalSupply, setTotalSupply] = useState(0)
  const { active, account, library } = useWallet();

  

  useEffect(() => {
    const init = async () => {
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        const { _hex } = await contract.totalSupply();
        console.log(contract,'contract')
        
      }
    }

    init()
  });

  const handleMint = async () => {
    try{
      if (library) {
        var transaction = '';
        const provider = new ethers.providers.Web3Provider(library.provider);
        const signer = provider.getSigner();
        console.log(signer)
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        console.log(contract)

        if (MINT_STATUS == 'private') {
          transaction = await contract.privateMint({ value: ethers.utils.parseEther(String(NFT_WHITELIST_PRICE)) });
        } else {
          transaction = await contract.publicMint({ value: ethers.utils.parseEther(String(NFT_PUBLIC_PRICE)) });
        }
        await transaction.wait();

        openAlert({ severity: 'success', message: 'Minted!' });

      } else {
        openAlert({ severity: 'error', message: 'Ethereum object doesn\'t exist' });
      }

    } catch (error) {
        openAlert({ severity: 'error', message: error.error.message });
    }

  };
  
  return (
    <RootStyle id="mint">
      <Stack spacing={5} alignItems="center" justifyContent={'center'} sx={{
        height: { xs: '200px', md: '700px' },
      }}>
        <MotionInView variants={varFadeInUp}>
          <Typography sx={{
            fontSize: { xs: '25px', md: '65px' },
            letterSpacing: '-1px',
            color: '#ffffff',
            fontFamily: "'Michroma', sans-serif",
            fontStyle: 'italic',
            fontWeight: 'bold'
          }}>
            JOIN THE <span style={{ color: '#d6e618' }}>PREPPERS</span>
          </Typography>
        </MotionInView>
        <MotionInView variants={varFadeInUp}>
          <Stack direction="row" spacing={3}>
            <Button
              sx={{
                width: { xs: '150px', md: '393px' },
                height: { xs: '30px', md: '78px' },
                fontSize: { xs: '20px', md: '45px' },
                borderRadius: '20px',
                filter: `drop-shadow(0px 10px 30px rgba(2,3,2,0.94))`,
                backgroundImage: `linear-gradient(0deg, #8e9b07 0%, #c3d216 100%)`,
                border: `4px solid #f1f1f1`,
                letterSpacing: '-1px',
                color: "#FFF",
                fontStyle: 'italic',
                fontWeight: 'bold',
                fontFamily: "'Michroma', sans-serif",
                "&:hover": {
                  color: 'white'
                }
              }}
              onClick={walletConnected ? handleMint : connectWallet}
            >
              {
                  walletConnected ?
                    'MINT' :
                    'CONNECT' 
              }
            </Button>
            <Button
              href="https://discord.gg/paratoken"
              sx={{
                width: { xs: '150px', md: '393px' },
                height: { xs: '30px', md: '78px' },
                fontSize: { xs: '18px', md: '45px' },
                borderRadius: '20px',
                filter: `drop-shadow(0px 10px 30px rgba(2,3,2,0.94))`,
                backgroundImage: `linear-gradient(0deg, #8e9b07 0%, #c3d216 100%)`,
                border: `4px solid #f1f1f1`,
                letterSpacing: '-1px',
                lineHeight: '713px',
                color: "#000000",
                fontFamily: "'Michroma', sans-serif",
                fontStyle: 'italic',
                fontWeight: 'bold',
                "&:hover": {
                  color: 'white'
                }
              }}
            >
              DISCORD
            </Button>
          </Stack>
        </MotionInView>
        <MotionInView variants={varFadeInUp}>
          <Typography sx={{
            color: '#d4e611',
            fontStyle: 'italic',
            fontWeight: 'bold',
            fontFamily: "'Michroma', sans-serif",
            fontSize: { xs: '25px', md: '55px' },
          }}>
            {totalSupply} / 3000
          </Typography>
        </MotionInView>
      </Stack>
    </RootStyle >
  );
}
