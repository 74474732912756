// material
import { styled, useTheme } from '@mui/material/styles';
import { Container, Typography, Stack, useMediaQuery, Grid, Button } from '@mui/material';
//
import { varFadeInUp, MotionInView } from '../../animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  background: '#000000',
  color: '#ffffff',
  margin: theme.spacing(14, 0, 0, 0),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  }
}));
// ----------------------------------------------------------------------

export default function LandingAdvanced() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <ContentStyle>
          <Stack spacing={5}>
            <Stack justifyContent={'center'} alignItems={'center'} spacing={2} pb={15}>
              
              <MotionInView variants={varFadeInUp}>
                <Stack justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
                  <Typography sx={{
                    fontSize: { xs: '35px', md: '65px' },
                    letterSpacing: '3px',
                    color: '#ffffff',
                    fontFamily: "'Russo One', sans-serif",
                    textAlign: 'center',
                  }}>
                    PARA PROS : <span style={{ color:"#D1AF76"}}>ALPHA UNIT</span>
                  </Typography>
                </Stack>
              </MotionInView>
              <MotionInView variants={varFadeInUp}>
                <Typography sx={{
                  fontSize: '16px',
                  letterSpacing: '0px',
                  lineHeight: '24px',
                  color: '#ffffff',
                  fontWeight: '300',
                  textAlign: 'center',
                  fontWeight: 'light',
                  fontFamily:"Poppins"
                }}>
                  PARA Werx is proud to present the release of their much anticipated, individually unique 100 piece PARA Pro Alpha Unit NFT collection. 
                  <br />
                  <br />
                  25% of all sales will go directly to buy back Para Werx tokens. 

                  Additionally, with each purchase from this NFT collection, you will be making an investment into the company; with 100% of profits going to directly fund project utility development.
                  <br />
                  <br />
                  With each purchase from the NFT collection, you will be making an investment into the company with profits going back into $Para to help fund marketing and development.
                </Typography>
              </MotionInView>
            </Stack>

            
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle >
  );
}
